html,
body {
}

ul {
  margin-top: 2px;
  padding-left: 30px;
}

.about-page {
  background-color: #181818;
  height: 100%;
}

.about-page-content {
  padding-top: 40px;
  text-align: left;
  color: #ffffff;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.about-page-leader {
  font-size: 24px;
  line-height: 30px;
}

p {
  line-height: 24px;
}
